
import random from "lodash/random"
import breakpoints from "@/assets/style/_breakpoints.scss"

export default {
  props: {
    title: { type: String, default: null },
  },

  data: () => ({
    tracks: [],
  }),

  async fetch () {
    await this.fetchTracks()
  },

  computed: {
    swiperOpts () {
      return {
        slidesPerView: this.tracks.length > 1 ? 1.2 : 1,
        breakpoints: {
          [breakpoints.xs]: {
            slidesPerView: 2,
          },
          [breakpoints.sm]: {
            spaceBetween: 32,
          },
          [breakpoints.md]: {
            slidesPerView: 3,
          },
        },
      }
    },
  },

  methods: {
    random,

    async fetchTracks () {
      const [res, err] = await this.$api.program.getTracks()
      if (err) console.error(err.response.data)
      else this.tracks = this.$api.helpers.getList(res)
    },
  },
}
