import { render, staticRenderFns } from "./LandingDemoHalls.vue?vue&type=template&id=6753f192"
import script from "./LandingDemoHalls.vue?vue&type=script&lang=js"
export * from "./LandingDemoHalls.vue?vue&type=script&lang=js"
import style0 from "./LandingDemoHalls.vue?vue&type=style&index=0&id=6753f192&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLoader: require('/app/components/app/AppLoader.vue').default,LandingDemoTitle: require('/app/components/landing/demo/LandingDemoTitle.vue').default,AppSwiper: require('/app/components/app/AppSwiper.vue').default})
