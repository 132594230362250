// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3gAz7NXO{padding:0 0 40px}@media(min-width:667.98px){._3gAz7NXO{padding:0 0 100px}}.Hxtyibpv{margin-bottom:1.25rem}@media(min-width:667.98px){.Hxtyibpv{margin-bottom:3.75rem}}.hYuiw0hF{background-repeat:no-repeat;background-size:cover;border-radius:20px;display:flex;flex-direction:column;height:80vw;max-height:480px;max-width:100%;padding:16px;transition:transform .15s ease-in-out;width:inherit}@media(hover:hover){.hYuiw0hF:hover{transform:scale(.98)}}@media(hover:none){.hYuiw0hF:active{transform:scale(.98)}}@media(min-width:667.98px){.hYuiw0hF{height:45vw;padding:50px}}@media(min-width:947.98px){.hYuiw0hF{height:32vw}}.lZ2BiMQU{display:flex;font-size:14px;justify-content:space-between;padding:0 0 16px}@media(min-width:947.98px){.lZ2BiMQU{font-size:24px;padding:0 0 30px}}.h3eWbNQ4{color:var(--color-text);opacity:.5}.Hs3u0tol{-webkit-background-clip:text;background-clip:text;background-image:linear-gradient(to right,var(--gradient-from),var(--gradient-to));-webkit-text-fill-color:transparent}.cAMUv1ht{flex-grow:1;font-size:24px;font-weight:400;margin:0}@media(min-width:947.98px){.cAMUv1ht{font-size:30px}}._25eBUI22{font-weight:400}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "_3gAz7NXO",
	"halls": "Hxtyibpv",
	"hall": "hYuiw0hF",
	"header": "lZ2BiMQU",
	"headerDate": "h3eWbNQ4",
	"headerTime": "Hs3u0tol",
	"title": "cAMUv1ht",
	"footer": "_25eBUI22"
};
module.exports = ___CSS_LOADER_EXPORT___;
